@import 'src/helpers/constants';


.singleTool {
  height: calc(100% - 66px);
}

.noProjectText {
  font-size: 24px;
  text-align: center;
  margin-top: 48vh;
}

.toolWrapper {
  padding: 94px 118px;
}

.toolTitle {
  font-size: 26px;
  margin-bottom: 4px;

  &Small {
    font-size: 18px;
  }
}

.toolUsers {
  margin: 40px 0;
  width: 40%;
}


.toolSettings {
  margin-top: 50px;

  &Row {
    display: flex;
    margin-bottom: 40px;
    width: 100%;
    align-items: center;
  }

  &Main {
    width: 76%;
  }

  &Title {
    font-family: Roboto-Regular, sans-serif;
    width: 24%;
  }
}

.radio-input {
  background: #000;
}

.authBoard {
  margin-bottom: 40px;
  background-color: #FFF4E4;
  box-shadow: inset -5px 0 20px #f0ebe4;
  padding: 20px;
  border-radius: 20px;
}

.authBoardRow {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  align-items: center;
}

.authBoardRow .toolSettingsMain {
  width: calc(76% + 10px);
  // width: 76%;
}

.authBoardRow .toolSettingsTitle {
  width: calc(24% - 10px);
  // width: 24%;
}

.authBoardRow:last-child {
  margin-bottom: 0;
}

.authBoardRow:last-child div p {
  margin: 0;
  height: 0;
}