@import "src/helpers/constants";

.my-select {
  &__control {
    border: 0 !important;
    border-radius: 10px !important;
    background-color: $main-color !important;
    padding: 11px 16px !important;
    cursor: pointer !important;
    box-shadow: 12px 12px 12px #f0ebe4, -12px -12px 12px #ffffff;
    transition: all .2s !important;

    &--is-focused {
      border: 0 !important;
      outline: none !important;
      box-shadow: 12px 12px 12px #f0ebe4, -12px -12px 12px #ffffff !important;
    }

    &--menu-is-open {
      border: 0 !important;
    }

    &:hover {
      transform: scale(1.01);
      box-shadow: 14px 14px 12px #f0ebe4, -14px -14px 12px #ffffff;
    }

    &:active {
      transform: scale(0.99);
      box-shadow: 5px 5px 6px #f0ebe4, -5px -5px 6px #ffffff;
    }
  }

  &__value-container {
    padding: 0 !important;
    overflow: visible !important;
  }

  &__indicators {
    //display: none !important;
  }

  &__multi-value {
    box-shadow: 5px 5px 6px #f0ebe4, -5px -5px 6px #ffffff !important;
    background-color: $main-color !important;
    cursor: default !important;
    font-size: 18px !important;

    &__remove {
      cursor: pointer !important;
      //background-color: $main-color !important;

    }
  }

  &__menu {
    box-shadow: 12px 12px 12px #f0ebe4, -12px -12px 12px #ffffff !important;
    background-color: $main-color !important;
  }

  &__option {
    cursor: pointer !important;
    background-color: $main-color !important;
    transition: background-color .2s;

    &--is-focused {
      background-color: darken($main-color, 4) !important;
    }

    &--is-selected {
      background-color: lighten($dark-orange, 24) !important;
      color: $text-color !important;
    }

    &:active {
      background-color: darken($main-color, 10) !important;
    }
  }

  &__placeholder {
    color: #AAAAAA !important;
    font-size: 14px;
  }

  &__indicator {
    padding: 0 6px !important;

    &-separator {
    }
  }
}
