@import 'src/helpers/constants';

.input_div {
  width: 100%;
  /*margin-bottom: 14px;*/

  .input {
    width: 100%;
    outline: none;
    border: 0;
    padding: 16px;
    display: block;
    border-radius: 10px;
    background-color: $main-color;
    box-shadow: inset 12px 12px 12px #f0ebe4, inset -12px -12px 12px #ffffff;
    transition: box-shadow .2s;

    &:focus {
      box-shadow: inset 12px 12px 12px $input-focus-color, inset -12px -12px 12px #ffffff !important;
    }

    &.error_input {
      box-shadow: inset 12px 12px 12px $input-error-color, inset -12px -12px 12px #ffffff;
    }

    &.date_input {
      width: 200px;
    }
  }

  .label {
    font-family: Roboto-Regular, sans-serif;
    margin-bottom: 6px;
    margin-left: 12px;
    display: inline-block;
    color: $label-color;

    &.error_label {
      color: $error-color;
    }
  }

  .error {
    font-family: Roboto-Light, sans-serif;
    color: $error-color;
    font-size: 14px;
    margin-top: 6px;
    margin-left: 12px;

    &Hidden {
      opacity: 0;
      cursor: default;
    }
  }
  .info {
    font-family: Roboto-LightItalic, sans-serif;
    color: $text-color;
    font-size: 16px;
    margin-top: 6px;
  }
}
