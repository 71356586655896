@import 'src/helpers/constants';


.noProjectText {
  font-size: 24px;
  text-align: center;
  margin-top: 48vh;
}

.editUsers {
  height: calc(100% - 66px);
}

.editUsersView {
  width: 80%;
  margin: 0 auto;
  padding: 14vh 0 6vh;

  &NoUserText {
    font-size: 20px;
    margin: 100px 0 20px;
  }

  &TopInput {
    width: 70%;
  }

  &BottomPart {
    margin-top: 100px;
  }

  &Text {
    font-size: 38px;
    margin-bottom: 30px;
    text-align: center;
    font-family: Roboto-Light, sans-serif;
  }

  &Title {
    font-size: 20px;
    margin-bottom: 30px;
    font-family: Roboto-Light, sans-serif;
  }

  &AddButton {
    margin-top: 40px;
    width: 20%;
  }
}

.modalWrapper {
  padding: 20px;
  width: 550px;

  &Text {
    font-size: 20px;
    margin-bottom: 30px;
    text-align: center;
    font-family: Roboto-Light, sans-serif;
  }
  &SmallText {
    font-size: 16px;
    margin-top: -10px;
    margin-bottom: 28px;
    font-family: Roboto-Light, sans-serif;
  }

  &Button {
    margin: 40px auto 0;
  }

  &ButtonsRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &InputBlock {
    margin-bottom: 30px;
  }
}

.userItem {
  display: flex;
  margin-bottom: 34px;

  &Block {
    width: 20%;
    padding: 0 8px;

    &Title {
      font-size: 16px;
      margin-bottom: 8px;
      color: $label-color;
      font-family: Roboto-Regular, sans-serif;
    }
  }

  &Buttons {
    display: flex;
    align-items: center;

    &First {
      margin-right: 10px;
    }
  }
}

.toolBlock {
  margin-bottom: 40px;
  &Title {
    font-size: 26px;
    margin-bottom: 20px;
  }

  .toolItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 22px;
    width: 100%;
    margin: 10px 0 20px;

    &Title {
      width: 12%;
      font-size: 18px;
    }
    &TypeTitle {
      width: 10%;
      font-size: 14px;
    }
    &Users {
      width: 30%;
    }
    &Button {}
  }

  .addTool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 14px;
    width: 70%;
  }

  .toolInput {

    &Wrapper {
      width: 50%;

    }
  }

  .toolSelectWrapper {
    width: 28%;
  }
}
