@import 'src/helpers/constants';

.buttonRow {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;

  &Circle {
    width: 16px;
    height: 16px;
    border: 1px solid $label-color;
    border-radius: 8px;
    padding: 2px;
    margin-right: 6px;

    &Active {
      border-color: $dark-orange;
    }

    &Inner {
      background-color: $dark-orange;
      border-radius: 15px;
      width: 100%;
      height: 100%;
    }
  }

  &Text {

  }
}
