@import "src/helpers/constants";

.wrapper {
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: auto;

  .mainRow {
    display: flex;
    height: 100vh;

    &_whole_height {
      height: 100%;
    }
  }

  .rightPart {
    width: calc(100% - 300px);
    height: 100%;

    &WholePart {
      width: 100%;
    }
  }

  .topBar {
    height: 66px;
    width: 100%;
  }

  .navBar {
    width: 300px;
  }

  .container {
    width: 100%;
    height: calc(100% - 66px);
    overflow: auto;
    position: relative;

    &WholePart {
      height: 100%;
    }

    &_with_paddings {
      padding: $main-paddings;
    }
  }
}

/*@media screen and (max-width: 1280px){
  .wrapper {
    .container {
      width: 100%;
    }
  }
}*/

