@import 'src/helpers/constants';


.createProjectView {
  width: 50%;
  margin: 30vh auto 0;
  height: 50%;

  &BottomPart {
    margin-top: 100px;
  }

  &Text {
    font-size: 38px;
    margin-bottom: 30px;
    text-align: center;
    font-family: Roboto-Light, sans-serif;
  }

  &Button {
    margin-top: 40px;
    width: 30%;
  }
}
