$main-color: #FFFAF3;
$text-color: #444444;
$label-color: #BBBBBB;
$label-light-color: #dedede;

$dark-orange: #FF9853;
$input-focus-color: rgba(255, 169, 83, 0.26);
$input-error-color: rgba(241, 70, 104, 0.2);

$error-color: #f14668;
$gradient-light: #FFB150;
$gradient-dark: #FF855B;
$yellow-glow: rgba(255, 187, 57, 0.65);


$main-color-hover: #ec5494;
$main-color-darker: #b40c52;
$accent-color-light: #eefff9;
$accent-color-text: #006b63;

$delete-light-color: #feecf0;
$delete-color: #f6c5d0;
$delete-text: #cc0f35;

$light-border-color: #dadada;
$light-background-color: #f3f3f3;
$cancel-background-color: #ffe08a;
$cancel-background-light-color: #fae7bd;
$cancel-background-dark-color: #ffce46;

$main-paddings: 30px;

$green_color: #30AD04;
$yellow_color: #E2CE19;
$red_color: #E21919;
