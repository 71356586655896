@import 'src/helpers/constants';

.wrapper {
  width: 500px;
  margin: 100px auto 70px;
}

.photoWrapper {
  width: 300px;
  height: 300px;
  padding: 4px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.photo {
  width: 100%;
  height: 100%;
  border-radius: 150px;
}
.photoEdit {
  width: 60px;
  height: 30px;
  border-radius: 30px 30px 0 0;
  background-color: $main-color;
  position: absolute;
  left: 120px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover &Icon {
    transform: scale(1.1) translateY(0);
  }

  &:active &Icon {
    transform: scale(0.95) translateY(3px);
  }

  &Icon {
    width: 14px;
    height: 14px;
    transform: translateY(2px);

    transition: transform .2s;
  }

  &Loading {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    left: 0;
    top: 0;
  }
}

.fullName {
  font-size: 30px;
  margin: 20px auto;
  text-align: center;
}

.nameInputRow {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}


.nameInputButton {
  margin-left: 14px;
}

.line {
  height: 1px;
  background-color: $label-light-color;
  margin: 10px 0 20px;

  &Second {
    margin: 100px 0 10px;
  }
}

.passwordSaveButton {
  margin: 0 auto;
}

.bottomButtonsRow {
  display: flex;
  margin: 50px 0 0;
  align-items: center;
  justify-content: space-evenly;
}

.modalWrapper {
  padding: 20px;
  width: 550px;

  &Text {
    font-size: 20px;
    margin-bottom: 30px;
    text-align: center;
    font-family: Roboto-Light, sans-serif;
  }
  &SmallText {
    font-size: 16px;
    margin-top: -10px;
    margin-bottom: 28px;
    font-family: Roboto-Light, sans-serif;
  }

  &Button {
    margin: 40px auto 0;
  }

  &ButtonsRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
