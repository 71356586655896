@import 'src/helpers/constants';

.wrapper {
  width: 100%;
  height: 100vh;
  //background-color: lighten($dark-orange, 6);
  background-color: darken($main-color, 3);

  .topPart {
    height: 66px;
    padding: 10px 20px;
    box-shadow: 0 5px 20px #d6d6d6;
    ;
  }

  .mainPart {
    height: calc(100% - 66px);
    box-shadow: inset -5px 0 20px #f0ebe4;
  }
}


.toolsList {
  height: calc(100% - 70px);
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  // padding: 30px 20px;
  padding: 30px 0;

  &::-webkit-scrollbar {
    display: none;
  }
}

.toolBlock {
  margin-bottom: 10px;
  box-shadow: 0 5px 20px #d6d6d6;

  &Title {
    font-size: 18px;
    font-family: Roboto-Regular, sans-serif;
    padding: 9px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color .2s;

    &:hover {
      background-color: darken($main-color, 8);
    }

    &:active {
      background-color: darken($main-color, 12);
    }

    &Arrow {
      width: 14px;
      transform: rotateZ(-90deg);
      transition: all .2s;

      &Down {
        transform: rotateZ(0);
      }
    }

    &Active {
      background-color: darken($main-color, 12);
    }
  }

  &List {
    // padding-left: 10px;
    transition: height .2s ease;
    overflow: hidden;

    &Item:first-child {
      border-top: 1px solid #BBBBBB;

    }

    &Item {
      margin-bottom: 18px;
      justify-content: flex-start;

      padding: 6px 18px;
      width: 100%;

      &Selected {
        padding: 7px 18px;
        background-image: linear-gradient(to right, $gradient-light , $gradient-dark);
        overflow: hidden;
        border-radius: 6px;
      }
    }

    &Closed {}
  }

  &NoTool {
    margin: 4px 0 0;
    text-align: center;
    font-size: 14px;
  }

  &Item {
    position: relative;
    font-family: Roboto-Regular, sans-serif;
    padding: 6px 20px 6px 30px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color .2s;

    &Panel {
      position: absolute;
      right: 10px;
      top: 5px;
      display: none;

      &Item {
        padding: 2px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        transition: background-color .2s;

        &:hover {
          background-color: darken($main-color, 6);
        }

        &:active {
          background-color: darken($main-color, 14);
        }

        &Img {
          width: 16px;
          height: 16px;
        }
      }
    }

    &:hover:not(:has(.toolBlockItemPanel:hover)) {
      background-color: darken($main-color, 8);
    }

    &:active:not(:has(.toolBlockItemPanel:hover)) {
      background-color: darken($main-color, 12);
    }

    &Selected {
      background-color: darken($main-color, 10);
    }

    &Dot {
      width: 4px;
      height: 0;
      background-color: $label-color;
      border-radius: 2px;
      margin: 0 8px;

      &Visible {
        height: 4px;
      }
    }

    &:hover &Panel {
      display: flex;
    }

  }

  ::selection {
    background-color: rgba(0, 0, 0, 0);
    color: $text-color
  }
}

.toolsBottom {
  padding: 0 20px;

  .line {
    margin: 14px 0 0;
    height: 1px;
    background-color: $label-color;
  }
}


.toolBlockBox {
  margin-bottom: 10px;

  &Title {
    font-size: 18px;
    font-family: Roboto-Regular, sans-serif;
    padding: 9px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color .2s;

    &:hover {
      background-color: darken($main-color, 8);
    }

    &:active {
      background-color: darken($main-color, 12);
    }

    &Arrow {
      width: 14px;
      transform: rotateZ(-90deg);
      transition: all .2s;

      &Down {
        transform: rotateZ(0);
      }
    }

    &Active {
      background-color: darken($main-color, 12);
    }
  }

  &List {
    padding-left: 10px;
    transition: height .2s ease;
    overflow: hidden;

    &Item {
      margin-bottom: 18px;
      justify-content: flex-start;

      padding: 6px 18px;
      width: 100%;

      &Selected {
        padding: 7px 18px;
        background-image: linear-gradient(to right, $gradient-light , $gradient-dark);
        overflow: hidden;
        border-radius: 6px;
      }
    }

    &Closed {}
  }

  &NoTool {
    margin: 4px 0 0;
    text-align: center;
    font-size: 14px;
  }

  &Item {
    position: relative;
    font-family: Roboto-Regular, sans-serif;
    padding: 6px 20px 6px 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color .2s;

    &Panel {
      position: absolute;
      right: 10px;
      top: 5px;
      display: none;

      &Item {
        padding: 2px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        transition: background-color .2s;

        &:hover {
          background-color: darken($main-color, 6);
        }

        &:active {
          background-color: darken($main-color, 14);
        }

        &Img {
          width: 16px;
          height: 16px;
        }
      }
    }

    &:hover:not(:has(.toolBlockItemPanel:hover)) {
      background-color: darken($main-color, 8);
    }

    &:active:not(:has(.toolBlockItemPanel:hover)) {
      background-color: darken($main-color, 12);
    }

    &Selected {
      background-color: darken($main-color, 10);
    }

    &Dot {
      width: 4px;
      height: 0;
      background-color: $label-color;
      border-radius: 2px;
      margin: 0 8px;

      &Visible {
        height: 4px;
      }
    }

    &:hover &Panel {
      display: flex;
    }

  }

  ::selection {
    background-color: rgba(0, 0, 0, 0);
    color: $text-color
  }
}