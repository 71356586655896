@import 'src/helpers/constants';

.wrapper {
  display: flex;
  align-items: center;
  position: relative;

  .main {
    width: 16px;
    height: 16px;
    padding: 2px;
    border-radius: 2px;
    box-shadow: inset 2px 2px 4px $input-focus-color;
    border: 1px solid $dark-orange;
    margin-right: 12px;
    cursor: pointer;
    transition: opacity .2s;

    &:hover {
      opacity: 0.5;
    }
    &:active {
      opacity: 0.2;
    }

    &Full {
      width: 100%;
      height: 100%;
      border-radius: 2px;
      background-color: $dark-orange;
    }
  }

  .rightPart {
    width: calc(100% - 30px);
  }

  .errorText {
    color: $error-color;
    position: absolute;
    left: -10px;
    top: 2px
  }
}
